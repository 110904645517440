.sidenav {
  height: 0;
  animation: slideDown 0.6s forwards;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  transition: 0.3s;
  background-color: black;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.3s;
  border-radius: 10px;
  display: flex;
}
.verticalCat {
  color: white;
  padding: 15px 0px;
  background: #309b65;
  border-radius: 0 5px 5px 0;
  font-size: 18px;
}
.verticalCat:hover {
  background-color: black;
}

@keyframes slideDown {
  0% {
    top: -120px;
  }
  100% {
    top: 0px;
  }
}
.sidenav li {
  margin-top: 27px !important;
  display: block;
  transition: 0.6s;
  color: white;
  width: auto;
  cursor: pointer;
  background-color: black;
  text-align: center;
  margin: auto;
}
.sidenav a {
  margin-top: 27px !important;
  display: block;
  transition: 0.6s ease;
  color: white;
  width: auto;
  cursor: pointer;
  background-color: black;
  text-align: center;
  margin: auto;
}